


//Project Name : OnGoesGold
export const FirebaseConfig = {
    apiKey: "AIzaSyDX16qC0k7T9Mippm7DIv746Y1GT_q5h0Y",
    authDomain: "ongoesgold.firebaseapp.com",
    databaseURL: "https://ongoesgold.firebaseio.com",
    projectId: "ongoesgold",
    storageBucket: "ongoesgold.appspot.com",
    messagingSenderId: "270358380687",
    appId: "1:270358380687:web:5434d3c01f4a8b620130fa",
    measurementId: "G-LJGR0QMTQY"
  };
export const google_map_key = 'AIzaSyDzd3eztOjioDXtgTmVrKl0-jG6sGf0FJY';


